const faders = document.querySelectorAll('.fade-in');
const faders2 = document.querySelectorAll('.fade-in2');

const appearOptions = {
  threshold: 0,
  rootMargin: "0px 0px 0px 0px"
};

const appearOnScroll = new IntersectionObserver((entries, appearOnScroll) => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      return;
    } else {
      if (entry.target.classList.contains('fade-in')) {
        entry.target.classList.add('appear');
      } else if (entry.target.classList.contains('fade-in2')) {
        entry.target.classList.add('appear2');
      }
      appearOnScroll.unobserve(entry.target);
    }
  });
}, appearOptions);

faders.forEach(fader => {
  appearOnScroll.observe(fader);
});

faders2.forEach(fader => {
  appearOnScroll.observe(fader);
});